import { h, Component } from 'preact';
import { Link } from '../link';
import {
  UseBuzzFeedNewsBrandLogo
} from '../../shared/svg/brands/news';
import styles from './styles.scss';
import { withTheme } from '../../shared/withTheme';

/*
 * BFN Logo
 * - theme variations: news_light, green, transparent
 * - top logo exists only when a theme is news_light
 * - green and transparent themes have white logo in the main nav, all the time
 * - news_light theme displays a logo in the main nav only when the nav becomes sticky
*/
class BfnLogo extends Component {
  render({ isSticking, isTop, showMoreNav, theme, themeName, trackingData }) {
    const isNewsHealthTheme = ['green', 'transparent'].includes(themeName);
    const Logo = isNewsHealthTheme && !isSticking ?
      <UseBuzzFeedNewsBrandLogo width="192" height="24" fill="white" /> :
      <UseBuzzFeedNewsBrandLogo />;

    const logoWrapperClassName = isSticking ?
      `${styles.logoWrapperSmall} ${theme.logoWrapperSmall}` :
      `${styles.logoWrapperMW} ${theme.logoWrapperMW}`;

    if (!isTop) { // logo from the nav bar
      return <div className={logoWrapperClassName}>
        <Link
          location={'logo'}
          label={'buzzfeednews_logo'}
          className={`${styles.headerTitle}`}
          href="/">
          {Logo}
        </Link>
      </div>;
    }
    // top logo
    return !isNewsHealthTheme && <div className={`${styles.logoWrapper} ${showMoreNav ? styles.withMoreNav : ''} embed-header-logo`}>
      <Link
        location={'logo'}
        label={'buzzfeednews_logo'}
        className={styles.headerTitle}
        href="/"
        trackingData={trackingData}
      >
        {Logo}
      </Link>
      <span className={styles.textRedCaps}>Reporting To You</span>
    </div>;
  }
}

export const ThemedBfnLogo = withTheme(BfnLogo);
