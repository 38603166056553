import { h, Component } from 'preact';
import styles from './styles.scss';
import { DestinationLogo } from '../../shared/svg/logos';
import { Link } from '../link';
import { Config } from '../../shared/context';
import { Button } from '../../shared/button';
import { breakpointSticky } from '../../sass/variables.scss';
import DESTINATIONS from '../../constants/destinations';
import { trackNavAction } from '../tracking/clientEventTracking';
import { withTheme } from '../../shared/withTheme';

let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}

const navMenuId = 'js-more-nav';

const isStickyEnabled = () => {
  return window.innerWidth >= parseInt(breakpointSticky, 10);
};

const destinationItemTitleId = name => `js-destination-item-${name}-more`;

const commonUserInfoTrackingData = {
  'unit_name': 'hidden',
  'subunit_type': 'component',
  'subunit_name': 'user',
  'item_type': 'text',
  'position_in_unit': 2
};

class Toggle extends Component {
  render({ children, theme, ...props }) {
    return (
      <Button {...props} className={`${styles.menuToggle} ${theme.menuToggle}`} onClick={this.props.onClick} location={'hamburger'}>
        <i>{children}</i>
      </Button>
    );
  }
}

export const ThemedToggle = withTheme(Toggle);

export class PageOverlay extends Component {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(evt) {
    // Escape key
    if (evt.keyCode === 27) {
      this.props.onHide(evt);
    }
  }

  componentDidMount() {
    if (isStickyEnabled()) {
      document.body.classList.add(styles.bodyWithMoreNav);
    }
    window.addEventListener('keydown', this.onKeyDown, true);
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.bodyWithMoreNav);
    window.removeEventListener('keydown', this.onKeyDown, true);
  }

  render({ top, onHide }) {
    if (!isStickyEnabled()) {
      return null;
    }

    return (
      <div className={styles.pageOverlay} style={`top:${top}px`} onMouseDown={onHide} role="presentation" />
    );
  }
}

const MoreNavInner = ({ navItems: { sections = [], topics = [], destinations = [] }, userInfo, onLogoutClicked }) => {
  const hasDestinations = destinations.length > 0;
  const hasSections = sections.length > 0;
  const hasTopics = topics.length > 0;
  // signIn is the first item - remove it for loggeed in users
  const _topics = userInfo ? topics.slice(1, 4) : topics.slice(0, 4);

  return (
    <Config.Consumer>
      {(config) => (
        <div className={styles.moreNavInner}>
          <h2 className={styles.moreNavLabel} id="nav-menu-title"> Browse links </h2>
          <div className={styles.sectionsSection}>
            {/* primary sections */}
            {hasSections &&
              <nav className={`${styles.section} ${styles.sectionLinksSection} ${styles.sectionLinksSectionPrimary}`} aria-label="Primary">
                <ul className={styles.sectionItems}>
                  {
                    sections.map(({ label, name, url }, index) => (
                      <li>
                        <Link
                          className={styles.link}
                          href={url}
                          label={label || name}
                          location={'moreMenu'}
                          trackingData={{
                            'destination': 'buzzfeed_news',
                            'position_in_unit': 1,
                            'position_in_subunit': index,
                            'subunit_type': 'component',
                            'subunit_name': 'sections'
                          }}
                        >
                          {label || name}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </nav>
            }
            {/* user info */}
            {userInfo &&
              <nav className={`${styles.section} ${styles.sectionLinksSection} ${styles.sectionLinksSectionUserInfo}`} aria-label="Sections">
                <ul className={styles.sectionItems}>
                  <li>
                    <Link
                      className={styles.link}
                      href={`https://www.buzzfeed.com/${userInfo.username}`}
                      location={'moreMenu'}
                      label={'profile'}
                      trackingData={{
                        ...commonUserInfoTrackingData,
                        'item_name': 'profile',
                        'position_in_subunit': 0
                      }}
                    >
                      {userInfo.displayName}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={styles.link}
                      href={'https://www.buzzfeed.com/settings'}
                      location={'moreMenu'}
                      label={'settings'}
                      trackingData={{
                        ...commonUserInfoTrackingData,
                        'item_name': 'settings',
                        'position_in_subunit': 1
                      }}
                    >
                      Account Settings
                    </Link>
                  </li>
                  <li>
                    <form action={`${config.bf_url}/auth/signout`} method="post">
                      <input
                        type="hidden"
                        name="_xsrf"
                        value={'logoutXsrf'}
                      />
                      <Button
                        onClick={onLogoutClicked}
                        location={'moreMenu'}
                        label={'log_out'}
                      >
                        Log Out
                      </Button>
                    </form>
                  </li>
                </ul>
              </nav>
            }
            {/* topics */}
            {hasTopics &&
              <nav className={`${styles.section} ${styles.sectionLinksSection}`} aria-label="Sections">
                <ul className={styles.sectionItems}>
                  {
                    _topics.map(({ label, name, url, trackingData }, index) => (
                      <li>
                        <Link
                          className={styles.link}
                          href={url}
                          label={label || name}
                          location={'moreMenu'}
                          trackingData={{
                            ...trackingData,
                            position_in_unit: 3,
                            position_in_subunit: index,
                            subunit_name: 'bfn_links',
                            subunit_type: 'component'
                          }}
                        >
                          {label || name}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </nav>
            }
            <div id={'CCPAModule'} className={`${styles.CCPAConsentModule}`}>
              <div className={`${styles.ccpaCopy}`}>
                US residents can opt out of "sales" of personal data.
              </div>
              <Button
                className={`ot-sdk-show-settings ${styles.smallSecondaryButton}`}
                onClick={() => {
                  trackNavAction({
                    action_type: 'show',
                    action_value: 'ccpa_module',
                    location: 'usprivacy'
                  });
                }}
                id="ot-sdk-btn"
              >
                Do Not Sell or Share My Personal Information
              </Button>
            </div>
          </div>
          {/* destination nav */}
          {hasDestinations &&
            <nav className={`${styles.section} ${styles.destinationsSection}`} aria-label="Browse brands">
              <ul className={styles.sectionItems}>
                {
                  destinations.map(({url, name, description, label, trackingData}, index) => (
                    <li className={styles.destinationItem}>
                      <Link
                        className={styles.destinationLink}
                        href={url} location={'moreMenu'}
                        label={label || name}
                        trackingData={{
                          ...trackingData,
                          subunit_name: 'other_sites',
                          subunit_type: 'component',
                          position_in_unit: 5,
                          position_in_subunit: index
                        }}
                      >
                        <h3>
                          <i className={styles[`${name}Logo`]}>
                            {name === 'bfo' && <DestinationLogo
                              aria-labelledby={destinationItemTitleId(name)}
                              destinationName={name}
                              role="img"
                              viewBox="0 0 1200 53.2"
                            >
                              <title id={destinationItemTitleId(name)}>{DESTINATIONS[name].displayName}</title>
                            </DestinationLogo>}
                            {name !== 'bfo' && <DestinationLogo
                              aria-labelledby={destinationItemTitleId(name)}
                              destinationName={name}
                              role="img"
                            >
                              <title id={destinationItemTitleId(name)}>{DESTINATIONS[name].displayName}</title>
                            </DestinationLogo>}
                          </i>
                        </h3>
                        <div>{description}</div>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </nav>
          }
        </div>
      )}
    </Config.Consumer>
  );
};

class MoreNav extends Component {
  /**
   * Limits the height of the component to viewport height
   */
  componentDidUpdate() {
    if (!this.props.isVisible || !isStickyEnabled()) { return; }

    const { top } = this.container.getBoundingClientRect();
    this.container.style.maxHeight = `${document.documentElement.clientHeight - top}px`;
  }

  render({ isVisible, ...props }) {
    const hasDestinations = props.navItems && props.navItems.destinations && props.navItems.destinations.length > 0;

    return (
      <section className={`${styles.moreNav} ${isVisible ? styles.visible : ''} ${hasDestinations ? styles.withDestinations : ''}`} id={navMenuId} ref={container => this.container = container} aria-labelledby="nav-menu-title" role="dialog" aria-modal="true">
        { isVisible && <FocusTrap focusTrapOptions={{clickOutsideDeactivates: true}}><MoreNavInner {...props} /></FocusTrap> }
      </section>
    );
  }
}

export const ThemedMoreNav = withTheme(MoreNav);
