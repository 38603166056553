/** @jsx h */
import { h, render } from 'preact';
import NewsHeader from './news_header';
import { headerContainerId } from './app-news-server';

function renderHeader(container) {
  render(<NewsHeader {...window.BZFD.__HEADER_STATE__} />, container, container.firstElementChild);
  delete window.BZFD.__HEADER_STATE__;
}

if (typeof window !== 'undefined') {
  let container = document.querySelector(`#${headerContainerId}`);

  if (container && window.BZFD && window.BZFD.__HEADER_STATE__) {
    renderHeader(container);
  } else {
    window.addEventListener('DOMContentLoaded', () => {
      container = document.querySelector(`#${headerContainerId}`);
      renderHeader(container);
    });
  }
}
