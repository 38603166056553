import { h, Component } from 'preact';
import { trackNavClick } from '../../shared/tracking/google';
import { trackNavClick as trackCETNavClick } from '../tracking/clientEventTracking';

export class Link extends Component {
  clickHandler({
    onClick,
    label,
    location,
    isInfoPage,
    ga,
    url,
    trackingData
  }) {
    return evt => {
      trackNavClick({ label, location, ga });
      trackCETNavClick({ location, url, isInfoPage, label, trackingData });
      return onClick(evt);
    };
  }

  render({ href = '', children, label = '', location = '', isInfoPage, onClick = () => {}, trackingData, ...props }) {
    // prepend buzzfeednews.com to relative links
    const url = href.match(/^https?:/) ? href : `https://stage.buzzfeednews.com${href}`;
    return (
      <a
        href={href}
        onClick={this.clickHandler({
          onClick,
          label,
          location,
          isInfoPage,
          ga: 'ga',
          url,
          trackingData
        })}
        {...props}
      >
        {children}
      </a>
    );
  }
}
