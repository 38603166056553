import { h, Component } from 'preact';
import { Button } from '../../shared/button';
import { Config } from '../../shared/context';
import { Link } from '../link';
import styles from './styles.scss';
import { UseDefaultUserIcon } from '../../shared/svg/icons/default-user';
import { trackNavAction } from '../tracking/clientEventTracking';

const Avatar = ({
  image,
  username,
  ...props
}) => {
  let avatar;

  if (image) {
    avatar = (
      <Config.Consumer>
        {(config) => (
          <img
            className={styles.userAvatar}
            src={config.image_service_url + image}
            alt={username}
            {...props}
          />
        )}
      </Config.Consumer>
    );
  } else {
    avatar = (
      <UseDefaultUserIcon
        className={`${styles.userAvatar}`}
        {...props}
      />
    );
  }

  return (
    <div className={styles.userAvatarContainer}>
      {avatar}
    </div>
  );
};

const UserOptions = ({
  displayName,
  username,
  onLogoutClicked,
  logoutXsrf
}) => {
  return (
    <Config.Consumer>
      {(config) => (
        <div className={styles.userMenu}>
          <ul className={styles.userOptionsLinks}>
            <li>
              <Link
                className={styles.userProfileLink}
                href={`https://www.buzzfeed.com/${username}`}
                location={'userMenu'}
                label={'profile'}
                trackingData={{'position_in_unit': 0}}
              >
                {displayName}
              </Link>
            </li>
            <li>
              <Link
                className={styles.userProfileLink}
                href={'https://www.buzzfeed.com/settings'}
                location={'userMenu'}
                label={'settings'}
                trackingData={{'position_in_unit': 1}}
              >
                Account Settings
              </Link>
            </li>
            <li>
              <form action={`${config.bf_url}/auth/signout`} method="post">
                <input
                  type="hidden"
                  name="_xsrf"
                  value={logoutXsrf}
                />
                <Button
                  onClick={onLogoutClicked}
                  location={'userMenu'}
                  label={'log_out'}
                >
                  Log Out
                </Button>
              </form>
            </li>
          </ul>
        </div>
      )}
    </Config.Consumer>
  );
};

export class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserMenu: false
    };
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }

  toggleUserMenu(e) {
    e.preventDefault();
    if (!this.state.showUserMenu) {
      trackNavAction({ location: 'user_menu', action_value: 'user_menu', action_type: 'show' });
    }
    this.setState({
      showUserMenu: !this.state.showUserMenu
    });
  }

  componentDidUpdate() {
    if (this.state.showUserMenu && this.props.isMoreNavDisplayed) {
      this.setState({
        showUserMenu: !this.state.showUserMenu
      });
    }
  }

  render({
    userInfo,
    ...props
  }, state) {
    return (
      <div className={state.showUserMenu && styles.userOptionsEnabled}>
        <button
          onClick={this.toggleUserMenu}
          aria-expanded={String(state.showUserMenu)}
          aria-label={'avatar'}
          className={styles.userMenuLink}
        >
          <Avatar
            image={userInfo && userInfo.image}
            username={userInfo && userInfo.username}
          />
          <div className={styles.userProfileName}>
            <span className={styles.userProfileNameText}>
              {userInfo && userInfo.displayName}
              <svg role="img" viewBox="0 0 141 65">
                <polygon points="70.5 0 141 65 0 65" />
              </svg>
            </span>
          </div>
        </button>
        <UserOptions
          {...userInfo}
          {...props}
        />
      </div>
    );
  }
}
