import {
  urlToId,
  cleanLink
} from '../../../utils/url';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import {
  createClientImpressionHandler,
} from '@buzzfeed/client-event-tracking/dist/lib/handlers';

const clientEventTracking = async () =>
  import(/* webpackChunkName: "clientEventTrackingSetup" */ '../../shared/tracking/clientEventTrackingSetup');

const EXTERNAL_LINK_LABELS = [
  'asis',
  'bfo',
  'buzzfeed.com',
  'goodful',
  'tasty',
  'profile',
  'settings',
  'Sign In'
];
const UNIT = {
  unit_type: 'nav',
  unit_name: 'main',
};

export const trackNavClick = ({
  label,
  location,
  url,
  isInfoPage,
  trackingData = {}
}) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  const baseWebContentData = {
    ...UNIT,
    position_in_unit: '',
    item_type: 'text',
    item_name: label ? label.toLowerCase().replaceAll(' ', '') : urlToId(url),
  };

  const eventWebContentData = {
    logo: {
      ...baseWebContentData,
      item_name: label
    },
    moreMenu: {
      ...baseWebContentData,
      unit_name: 'hidden'
    },
    userMenu: {
      ...baseWebContentData,
      unit_name: 'profile_nav'
    }
  };

  let webContentData = eventWebContentData[location] || baseWebContentData;

  let targetContentType;
  let targetContentId;

  // userMenu and/or moreMenu
  if (label === 'log_out') {
    webContentData['position_in_unit'] = 2;
  }

  if (location === 'userMenu') {
    webContentData['item_type'] = 'text';
    if (label === 'profile') {
      webContentData['item_name'] = 'profile';
      targetContentId = cookies.get('user_uuid') || 'unauthenticated';
      targetContentType = 'user';
    } else {
      targetContentType = 'url';
      targetContentId = url;
      webContentData['item_name'] = label
        .toLowerCase()
        .replace(/ /g, '_');
    }
  } else if (location === 'moreMenu' && label === 'log_out') {
    webContentData['position_in_subunit'] = 2;
    webContentData['subunit_name'] = 'user';
    webContentData['subunit_type'] = 'component';
  }

  if (isInfoPage) {
    targetContentType = 'info';
  }

  if (location === 'logo') {
    targetContentId = label;
  }

  if (EXTERNAL_LINK_LABELS.includes(label.toLowerCase())) {
    webContentData.item_name = label.toLowerCase();

    trackClientEventExternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_url: cleanLink(url),
        ...trackingData,
      },
      layers: [buzzLayer()],
    });
  } else {
    if (!targetContentId) {
      targetContentId = label ? label.toLowerCase().replace(/ /g, '') : (urlToId(url) || cleanLink(url));
    }
    trackClientEventInternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_type: targetContentType || 'feed',
        target_content_id: targetContentId,
        ...trackingData
      },
      layers: [buzzLayer()],
    });
  }
};

export const trackNavAction = ({ location, action_value, action_type, item_name }) => {

  const buzzLayer = getBuzzLayer();
  const eventWebContentData = {
    ccpa: {
      ...UNIT,
      unit_name: 'hidden',
      item_type: 'button',
      item_name: 'ccpa_button',
      position_in_unit: 4,
      position_in_subunit: null
    },
    hamburger: {
      click_type: 'left',
      item_type: 'button',
      item_name,
      unit_name: 'main',
      unit_type: 'nav',
      position_in_unit: 1,
    },
    user_menu: {
      ...UNIT,
      item_type: 'text',
      item_name: 'user_menu',
      position_in_unit: 2,
      position_in_subunit: null
    },
  };

  if (buzzLayer === undefined || !eventWebContentData[location]) {
    return;
  }

  trackClientEventContentAction({
    layers: [
      eventWebContentData[location],
      {
        subunit_type: '',
        subunit_name: '',
        action_value,
        action_type,
      },
      buzzLayer(),
    ],
  });
};

async function trackClientEventInternalLink({ linkData, layers }) {
  const { internalClick } = await clientEventTracking();
  internalClick(linkData, ...layers);
}

async function trackClientEventExternalLink({ linkData, layers }) {
  const { externalClick } = await clientEventTracking();
  externalClick(linkData, ...layers);
}

export async function trackClientEventContentAction({ layers }) {
  const { contentAction } = await clientEventTracking();
  contentAction(...layers);
}

export async function trackClientEventImpression({trackingData = {}}) {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }
  const { impression } = await clientEventTracking();
  impression({
    ...UNIT,
    ...trackingData
  }, buzzLayer());
}

export const createImpressionHandler = () => createClientImpressionHandler(trackClientEventImpression);
// unable create `attachClientImpressionHandler` because tests fail with error
// `ReferenceError: IntersectionObserver is not defined`
// export const attachClientImpressionHandler = createClientImpressionHandler(trackClientEventImpression);

function getBuzzLayer() {
  if (typeof window === 'undefined'
    || !window.clientEventTracking
    || typeof window.clientEventTracking.getPageContextLayer !== 'function'
  ) {
    return undefined;
  }

  return window.clientEventTracking.getPageContextLayer();
}
