import { h, Component } from 'preact';
import { ThemedMoreNav } from '../more-nav';
import styles from './styles.scss';
import { wrapper } from '../news_header.scss';
import { withTheme } from '../../shared/withTheme';

class MainNav extends Component {
  render({ navItems, showMoreNav, isSticking, children, userInfo, onLogoutClicked, theme }) {
    return (
      <div
        className={`${styles.mainNavContainer} ${theme.mainNavContainer} js-main-nav
          ${isSticking ? styles.stickyMainNav : ''}
          ${isSticking ? theme.stickyMainNav : ''}
          ${showMoreNav ? styles.withMoreNav : ''}
        `}
      >
        <div className={wrapper}>
          <div className={styles.mainNav}>
            {children}
          </div>
          <ThemedMoreNav
            userInfo= {userInfo}
            navItems={navItems}
            isVisible={showMoreNav}
            onLogoutClicked={onLogoutClicked}
          />
        </div>
      </div>
    );
  }
}

export const ThemedMainNav = withTheme(MainNav);
