import { h, Component } from 'preact';
import { throttle } from 'lodash-es';
import auth from '@buzzfeed/buzzblocks/js/services/auth';
import styles from './news_header.scss';
import { Config, Theme } from '../shared/context';
import { ThemedTopicNav } from './topic-nav';
import { ThemedBfnLogo } from './bfn-logo';
import { Link } from './link';
import { ThemedToggle } from './more-nav';
import { UserMenu } from './user-menu';
import { ThemedMainNav } from './main-nav';
import { breakpointSticky, breakpointLarge } from '../sass/variables.scss';
import { UseHamburgerIcon } from '../shared/svg/icons/hamburger';
import { UseCloseIcon } from '../shared/svg/icons/close';
import { PageOverlay } from '../header/more-nav';
import { trackNavAction, trackNavClick } from './tracking/clientEventTracking';
import { withTheme } from '../shared/withTheme';

function getUserInfo() {
  if (!auth.isLoggedIn()) {
    return null;
  }
  const userInfo = auth.getUserInfo();
  return {
    id: userInfo.userid,
    displayName: userInfo.display_name,
    image: userInfo.image,
    username: userInfo.username
  };
}

class NewsHeaderInner extends Component {
  constructor() {
    super();
    this.state = {
      showMoreNav: false,
      isSticking: false,
      overlayOffset: 0,
      userInfo: null,
      logoutXsrf: null
    };
    this.toggleMoreNav = this.toggleMoreNav.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onScroll = throttle(this.onScroll.bind(this), 50);
  }

  overlayTopOffset() {
    const containerHeight = this.stickyContainer.clientHeight;
    const { y: containerY } = this.stickyContainer.getBoundingClientRect();
    return this.state.isSticking
      ? containerHeight
      : containerHeight + containerY;
  }

  toggleMoreNav(e) {
    e.preventDefault();
    const type = this.state.showMoreNav ? 'hide' : 'show';
    const name = this.state.showMoreNav ? 'close' : 'open';
    trackNavAction({ location: 'hamburger', action_value: 'overlay', action_type: type, item_name: name });
    this.setState({
      ...this.state,
      showMoreNav: !this.state.showMoreNav
    });
  }

  /**
   * Listener for updating the sticky behavior when header is being scrolled into or out of view
   */
  onScroll() {
    const { top } = this.stickyContainer.getBoundingClientRect();
    const offset = ['green', 'transparent'].includes(this.props.themeName) ? -266 : 0;
    const nextIsSticking = top < offset;
    if (this.state.isSticking === nextIsSticking) {
      return;
    }

    this.setState({
      ...this.state,
      isSticking: nextIsSticking,
    });
  }

  componentDidMount() {
    const userInfo = getUserInfo();

    this.setState({
      ...this.state,
      userInfo
    });

    this.handleLogoutFormState();

    // don't sticky the nav if we are zoomed in very closely or the nav will block a lot of the page
    if (
      window.innerWidth >= parseInt(breakpointSticky, 10) &&
      window.innerHeight > this.stickyContainer.clientHeight * 3
    ) {
      window.addEventListener('scroll', this.onScroll);
      this.stickyContainer.style.height = `${this.stickyContainer.clientHeight}px`;
      this.onScroll();

      // check fixed height on resize
      if (window && window.matchMedia) {
        const stickyBreak = window.matchMedia(
          `(min-width: ${breakpointLarge})`
        );
        stickyBreak.addListener(() => {
          this.stickyContainer.style.height = '';
          window.requestAnimationFrame(() => {
            this.stickyContainer.style.height = `${this.stickyContainer.clientHeight}px`;
          });
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate() {
    // Update overlay position if any popups is opened
    if (this.state.showMoreNav) {
      if (this.state.overlayOffset !== this.overlayTopOffset()) {
        this.setState({
          ...this.state,
          overlayOffset: this.overlayTopOffset()
        });
      }
      this.checkElementPosition(this.stickyContainer, function() {
        this.setOverlayPosition();
      }.bind(this));
    } else {
      clearTimeout(this.stickyContainer.onElementPositionChangeTimer);
    }
  }

  setOverlayPosition() {
    this.setState({
      ...this.state,
      overlayOffset: this.overlayTopOffset()
    });
  }

  checkElementPosition(elm, callback) {
    let {bottom} = elm.getBoundingClientRect();

    (function run() {
      let rect = elm.getBoundingClientRect();

      if(bottom !== rect.bottom) {
        callback();
      }
      bottom = rect.bottom;
      if(elm.onElementPositionChangeTimer) {
        clearTimeout(elm.onElementPositionChangeTimer);
      }
      elm.onElementPositionChangeTimer = setTimeout(run, 200);
    })();
  }

  onLogout(e, label, location) {
    const signout = e.target.form;
    trackNavClick({ label, location });
    this.setLogoutXsrfFromCookies();
    if (!this.state.logoutXsrf) {
      // cant logout without xsrf token
      return;
    }
    signout.submit();
  }

  handleLogoutFormState() {
    if (!auth.isLoggedIn()) {
      return;
    }

    this.setLogoutXsrfFromCookies();
    if (!this.state.logoutXsrf) {
      // we have to go get a xsrf cookie
      fetch('/auth/signin').then(() => {
        this.setLogoutXsrfFromCookies();
      });
    }
  }

  setLogoutXsrfFromCookies() {
    document.cookie.split('; ').forEach((item) => {
      if (item.startsWith('_xsrf')) {
        this.setState({
          logoutXsrf: item.split('=')[1],
        });
      }
    });
  }

  render({ navItems, theme }, state) {
    return (
      <div className={state.isSticking ? theme.sticky : ''}>
        <div className={`${theme.bottomBorder}`}/>
        <header className={styles.header}>
          <ThemedBfnLogo
            isTop={true}
            showMoreNav={state.showMoreNav}
            isSticking={state.isSticking}
            trackingData={{
              'item_type': 'image',
              'target_content_id': 1
            }}
          />
          <div className="js-sticky-container"
            ref={(el) => (this.stickyContainer = el)}
          >
            <ThemedMainNav
              isSticking={state.isSticking}
              navItems={navItems}
              onLogoutClicked={this.onLogout}
              showMoreNav={state.showMoreNav}
              userInfo={state.userInfo}
            >
              <ThemedToggle
                onClick={this.toggleMoreNav}
                aria-label="open menu to see more links"
              >
                {state.showMoreNav ? (
                  <UseCloseIcon width={22} height={22} aria-hidden={true} />
                ) : (
                  <UseHamburgerIcon />
                )}
              </ThemedToggle>
              <ThemedBfnLogo
                isSticking={state.isSticking}
                showMoreNav={state.showMoreNav}
              />
              <div className={`${styles.signInMW} ${theme.signInMW}`}>
                {state.userInfo ?
                  <UserMenu
                    userInfo={state.userInfo}
                    isMoreNavDisplayed={state.showMoreNav}
                    onLogoutClicked={this.onLogout}
                  /> :
                  <Link
                    className={`${styles.signInLink} ${theme.signInLink}`} href={navItems.topics[0].url}
                    children={navItems.topics[0].name}
                    label={navItems.topics[0].name}
                    trackingData={{...navItems.topics[0].trackingData, position_in_unit: 2}}
                  />
                }
              </div>
              <ThemedTopicNav
                navItems={navItems}
                userInfo={state.userInfo}
                isMoreNavDisplayed={state.showMoreNav}
                onLogoutClicked={this.onLogout}
              />
            </ThemedMainNav>
          </div>
          {state.showMoreNav && (
            <PageOverlay
              top={state.overlayOffset}
              onHide={this.toggleMoreNav}
            />
          )}
        </header>
      </div>
    );
  }
}

const NewsHeaderInnerThemed = withTheme(NewsHeaderInner);

export default class NewsHeader extends Component {
  render({ navItems, config, theme }) {
    return (
      <Config.Provider value={config}>
        <Theme.Provider value={theme}>
          <NewsHeaderInnerThemed navItems={navItems} />
        </Theme.Provider>
      </Config.Provider>
    );
  }
}
