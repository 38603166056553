import { h, Component } from 'preact';
import styles from './styles.scss';
import { Link } from '../link';
import { UserMenu } from '../user-menu';
import { withTheme } from '../../shared/withTheme';

class TopicNav extends Component {
  constructor() {
    super();
    this.state = {
      redirectUrl: ''
    };
  }

  componentDidMount() {
    this.setState({ redirectUrl: encodeURIComponent(window.location.href) });
  }

  render({ navItems, isMoreNavDisplayed, userInfo, onLogoutClicked, theme }) {
    return (
      <nav className={`${theme.navigationLinks} ${styles.wrapper}`}>
        {navItems && navItems.topics &&
          <ul className={`${styles.navigationLinks__ul} ${styles.topicNav}`}>
            {
              navItems.topics.map(({url, name, signIn, trackingData}, index) => {
                let href = signIn ? `${url}redirect=${this.state.redirectUrl}` : url;
                return (
                  <li className={`${styles.navigationLinks__li} ${styles.topicNavItem} ${theme.navigationLinks__li}`}>
                    {
                      signIn && userInfo ?
                        <UserMenu
                          userInfo={userInfo}
                          isMoreNavDisplayed={isMoreNavDisplayed}
                          onLogoutClicked={onLogoutClicked}
                        /> :
                        <Link
                          className={`${styles.navigationLink} ${theme.navigationLink} ${theme.link}`} href={href}
                          children={name}
                          label={name}
                          trackingData={{...trackingData, position_in_unit: index + 2}}
                        />
                    }
                  </li>
                );
              })
            }
          </ul>
        }
      </nav>
    );
  }
}

export const ThemedTopicNav = withTheme(TopicNav);
