import {
  urlToId,
  cleanLink
} from '../../../utils/url';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import {
  createClientImpressionHandler,
} from '@buzzfeed/client-event-tracking/dist/lib/handlers';

const clientEventTracking = async () =>
  import(/* webpackChunkName: "clientEventTrackingSetup" */ '../../shared/tracking/clientEventTrackingSetup');

const EXTERNAL_LINK_LABELS = ['bfnews', 'tasty'];
const UNIT = {
  unit_type: 'nav',
  unit_name: 'main',
};

export const trackNavClick = ({
  label,
  location,
  url,
  isInfoPage,
  trackingData = {}
}) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  const baseWebContentData = {
    ...UNIT,
    position_in_unit: '',
    item_type: 'text',
    item_name: urlToId(url) || label.toLowerCase(),
  };

  const eventWebContentData = {
    badgeBar: {
      ...baseWebContentData,
      item_type: 'badge'
    },
    logo: {
      ...baseWebContentData,
      item_name: label
    },
    trendingBar: {
      ...baseWebContentData,
      unit_name: 'topic_nav'
    },
    moreMenu: {
      ...baseWebContentData,
      unit_name: 'hidden'
    },
    userMenu: {
      ...baseWebContentData,
      unit_name: 'profile_nav'
    }
  };

  let webContentData = eventWebContentData[location] || baseWebContentData;

  let targetContentType;
  let targetContentId;

  if (location === 'userMenu') {
    if (label === 'notification-link') {
      webContentData['item_type'] = 'notification';
      webContentData['item_name'] = trackingData['item_name'] || '';
      targetContentType = ''; //todo
      targetContentId = ''; //todo
    } else if (label === 'teaser-notification-link') {
      webContentData['item_type'] = 'teaser_notification';
      webContentData['item_name'] = trackingData['item_name'] || '';
      targetContentType = ''; //todo
      targetContentId = ''; //todo
    } else if (label === 'profile') {
      webContentData['item_type'] = 'profile';
      webContentData['item_name'] = 'icon';
      targetContentId = cookies.get('user_uuid') || 'unauthenticated';
      targetContentType = 'user';
    } else {
      webContentData['item_type'] = 'text';
      targetContentType = 'url';
      targetContentId = url;
      webContentData['item_name'] = label
        .toLowerCase()
        .replace(/ /g, '_');
    }
  }

  if (isInfoPage) {
    targetContentType = 'info';
  }

  if (location === 'logo') {
    targetContentId = label;
  }

  if (label === 'merch') {
    webContentData.item_name = 'merch';
    targetContentId = 'shop.buzzfeed.com';
  }

  if (EXTERNAL_LINK_LABELS.includes(label.toLowerCase())) {
    webContentData.item_name = label.toLowerCase();

    trackClientEventExternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_url: cleanLink(url),
      },
      layers: [buzzLayer()],
    });
  } else {
    trackClientEventInternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_type: targetContentType || 'feed',
        target_content_id: targetContentId || urlToId(url) || cleanLink(url),
        ...trackingData
      },
      layers: [buzzLayer()],
    });
  }
};

export const trackNavAction = (eventData) => {
  const { location, action_value, action_type } = eventData;
  const buzzLayer = getBuzzLayer();
  const subunitValues = { subunit_type: '', subunit_name: '' };

  const eventWebContentData = {
    search: {
      ...UNIT,
      item_type: 'submission',
      item_name: 'search',
      position_in_unit: 7,
      action_type: 'search',
    },
    ccpa: {
      ...UNIT,
      unit_name: 'hidden',
      item_type: 'button',
      item_name: 'ccpa_button',
      action_type: 'show',
    },
    notificationMenu: {
      ...UNIT,
      item_type: 'button',
      item_name: 'notification_menu',
      action_type: 'show',
    },
    teaserNotificationMenu: {
      ...UNIT,
      item_type: 'button',
      item_name: 'teaser_notification_menu',
      action_type: 'show',
    },
    signInModal: {
      ...UNIT,
      item_type: 'text',
      item_name: 'auth_signin',
      action_type,
    },
    newsletterSignupModal: {
      ...UNIT,
      item_type: eventData['item_type'],
      item_name: eventData['item_name'],
      action_type,
      action_value,
    }
  };

  if (location === 'newsletterSignupModal') {
    subunitValues['subunit_type'] = 'component';
    subunitValues['subunit_name'] = 'newsletter_subscription';
  }

  if (buzzLayer === undefined || !eventWebContentData[location]) {
    return;
  }

  trackClientEventContentAction({
    layers: [
      eventWebContentData[location],
      {
        ...subunitValues,
        action_value: action_value,
      },
      buzzLayer(),
    ],
  });
};

export const trackNavExperiments = (experiments) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  const experiment_id = [];
  Object.keys(experiments.eligible).forEach(key => {
    const experiment = experiments.eligible[key];
    if (experiment && experiment.value) {
      experiment_id.push(
        [
          key,
          experiment.id,
          experiment.version,
          experiment.value,
          experiment.variant_id,
        ].join('|')
      );
    }
  });

  trackClientExperimentActive({
    layers: [buzzLayer()],
    experiment_id
  });
};

async function trackClientEventInternalLink({ linkData, layers }) {
  const { internalClick } = await clientEventTracking();
  internalClick(linkData, ...layers);
}

async function trackClientEventExternalLink({ linkData, layers }) {
  const { externalClick } = await clientEventTracking();
  externalClick(linkData, ...layers);
}

export async function trackClientEventContentAction({ layers }) {
  const { contentAction } = await clientEventTracking();
  contentAction(...layers);
}

async function trackClientExperimentActive({ layers, experiment_id }) {
  const { abTest } = await clientEventTracking();
  abTest(...layers, { experiment_id });
}

export async function trackClientEventImpression(eventData) {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }
  const { impression } = await clientEventTracking();
  impression({
    ...UNIT,
    ...eventData
  }, buzzLayer());
}

export const trackNavSignInClick = (eventData) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  trackClientEventExternalLink({
    linkData: {
      click_type: 'left',
      ...eventData,
    },
    layers: [buzzLayer()],
  });
};

export const createImpressionHandler = createClientImpressionHandler(trackClientEventImpression);
// 1. Use this in a component imported in the header index file (hat wraps tag with Tracker.Provider
// 2. Inside your component file: import { Tracking } from '../context';
// 3. Define context: static contextType = Tracking;  or wrap Tracking.Consumer
// See https://bit.ly/3RrFUPD
// And https://bit.ly/3XiFfnz
// 4. Use createRef(); instance and set on the HTML element to be tracked
//    this.exampleButton = createRef();
//     <button ref={this.exampleButton}>test</button>
// 5. Add handlers to componentDidMount and componentWillUnmount for cleanup
//  componentDidMount() {
//     const detachHandler = this.context.createImpressionHandler(this.exampleButton.current, { ...trackingData });
//     this.detachImpressionHandler = () => detachHandler();
//  }
// componentWillUnmount() {
//    if (typeof this.detachImpressionHandler === 'function') {
//       this.detachImpressionHandler();
//     }
//  }

function getBuzzLayer() {
  if (typeof window === 'undefined'
    || !window.clientEventTracking
    || typeof window.clientEventTracking.getPageContextLayer !== 'function'
  ) {
    return undefined;
  }

  return window.clientEventTracking.getPageContextLayer();
}
